import $ from "jquery"

$(document).ajaxComplete((event, request)=> {
	let types = ['alert', 'error', 'notice', 'success', 'warning']

	for (let a = 0; a < types.length; a++) {
		let message = request.getResponseHeader('X-Ajax-' + types[a].toUpperCase())

		if (message !== null) {
			let container = document.getElementsByClassName('alert-container')[0]

			if (container) {
				let element = document.createElement('div')
				element.classList.add('alert alert-' + types[a])

				let text = document.createTextNode(decodeURIComponent(escape(message)))

				element.appendChild(text)
				container.appendChild(element)
			}
		}
	}
})

document.addEventListener('turbolinks:load', ()=> {
	$.ajaxSetup({
		headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
	})
})
