import $ from 'jquery'
import ClipboardJS from 'clipboard'

document.addEventListener('turbolinks:load', () => {
	function setTooltip(trigger, message) {
		let old_message = trigger.dataset.originalTitle

		trigger.dataset.originalTitle = message

		$(trigger).tooltip('show')

		trigger.dataset.originalTitle = old_message
	}

	let clipboard = new ClipboardJS('.js-copy', {
		text: (trigger) => {
			if (trigger.dataset.clipboardText) {
				return trigger.dataset.clipboardText
			} else {
				return trigger.text
			}
		}
	})

	clipboard.on('success', (e) => {
		e.clearSelection()

		setTooltip(e.trigger, 'Copied')
	})

	clipboard.on('error', (e) => {
		setTooltip(e.trigger, 'The function is not supported')
	})
})
