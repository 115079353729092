document.addEventListener('turbolinks:load', ()=> {
	Array.from(document.getElementsByClassName('transactions-select-type')).forEach((element) => {
		element.addEventListener('change', (event)=> {
			let value = element.options[element.selectedIndex].value

			if (value === '') {
				window.location.href = window.location.href.split(/\?/)[0]
			} else {
				window.location.href = '?type=' + value;
			}

			event.preventDefault()
		})
	})
})
