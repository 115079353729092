document.addEventListener('turbolinks:before-visit', ()=> {
	window.turbolinks_referer = location.href
})

document.addEventListener('turbolinks:load', ()=> {
	if (window.turbolinks_referer) {
		if (window.ga) {
			window.ga('send', 'pageview')
		}
	}
})
